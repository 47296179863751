<script>
import orderService from '../../services/order.service'
import Pagination from '../../components/Pagination.vue'
import StatusButton from '../../components/StatusButton.vue'
import TableData from '../../components/TableData.vue'
import { decimalInputOnly } from '../../utils'
import { statusMapping, statusTypes } from '../../utils/constants'
import OrderStatusDetails from './Dialogs/OrderStatusDetails.vue'
export default {
  name: 'Product',
  data: () => ({
    uploadedReference: '',
    selectedId: null,
    selectedStatus: null,
    currentPage: 1,
    limit: 10,
    orderDetails: {},
    driver: {
      name: '',
      phone: '',
      driverTracking: '',
      trackingNumber: ''
    },
    paymentOptions: [
      { value: null, text: '付款方式' },
      { value: 'FPS', text: 'FPS' },
      { value: 'VISA', text: 'VISA' },
      { value: 'MASTERCARD', text: 'MASTERCARD' },
      { value: 'AMEX', text: 'AMERICAN EXPRESS' },
      { value: 'UNIONPAY', text: '銀聯' },
      { value: 'ALIPAY', text: '支付寶' }
    ],
    filters: {
      name: '',
      paymentMethod: null
    },
    isCancelEntire: false,
    selectedRefundMethod: '',
    refundAmount: null,
    setNewAddress: '',
    headers: [
      { text: '會員編號', value: 'posMemberId' },
      { text: '訂單編號', value: 'orderId' },
      { text: '使用者身分', value: 'customerId' },
      { text: '顧客姓名', value: 'accountName' },
      { text: '電話號碼', value: 'accountPhoneNumber' },
      { text: '訂單履行方式', value: 'orderFulFilmentMethod' },
      { text: '地址', value: 'address', class: 'text-normal' },
      { text: '狀態', value: 'status' },
      { text: '付款方式', value: 'paymentMethod' },
      { text: '參考', value: 'reference' },
      { text: '創建日期', value: 'createdDate' },
      { text: '操作', value: 'action' }
    ],
    isLoading: false,
    refundDetails: null,
    refundItems: [],
    isSubmitting: false,
    isReject: false,
    statusMapping: {},
    statusTypes: [],
    statusesInfo: []
  }),
  components: {
    Pagination,
    StatusButton,
    TableData,
    OrderStatusDetails
  },
  mounted () {
    this.statusMapping = statusMapping
    this.statusTypes = statusTypes
    this.ordersInit()
  },
  computed: {
    refundItemsMapping () {
      if (!(this.refundItems.length || Object.keys(this.orderDetails).length || this.orderDetails.orderItems?.length)) {
        return []
      }
      const refundItems = this.refundItems?.map((item) => {
        const originalItem = this.orderDetails.orderItems?.find((f) => f.productId === item.productId)
        let total
        let optionValue
        if (originalItem) {
          total = (originalItem.finalOrderItemAmount / Number(originalItem.quantity)) * Number(item.quantity)
          optionValue = item.productOptionViewDTOs.length ? item.productOptionViewDTOs[0].optionValue : null
        } else {
          total = 0
          optionValue = null
        }
        return {
          optionValue,
          productId: item.productId,
          quantity: +item.quantity,
          total
        }
      })
      if (refundItems.length) {
        return refundItems
      }
      return []
    },
    sumRefundAmount () {
      if (!this.refundItemsMapping.length) return 0
      const items = this.refundItemsMapping.filter((s) => s.quantity)
      if (this.isCancelEntire) {
        return {
          amount: this.orderDetails.finalAmount
        }
      }
      return {
        amount: items?.map((e) => e.total).reduce((a, b) => a + b, 0),
        orderCancellationDTOs: items?.map((s) => ({
          optionValue: s.optionValue,
          productId: s.productId,
          quantity: s.quantity
        })),
        refundMethod: this.selectedRefundMethod
      }
    },
    maxRefundAmount () {
      if (Object.keys(this.orderDetails).length) {
        if (this.orderDetails.refundedAmount) {
          return Number((this.orderDetails.finalAmount - this.orderDetails.refundedAmount).toFixed(2))
        }
        return this.orderDetails.finalAmount
      }
      return 0
    },
    filterOrderList () {
      const orderList = this.$store.getters.getOrderList
      if (this.$route.params.status.toString() === 'PLACED') {
        return orderList.filter(
          (item) => item.status === 'PLACED'
        )
      }
      return orderList.filter(
        (item) => item.status === this.$route.params.status + ''
      )
    },
    filterOrders () {
      const normalizedFilterName = this.filters.name.toLowerCase().replace(/\s+/g, '')

      const matchesFilter = (item, field) => {
        return field?.toString().toLowerCase().replace(/\s+/g, '').includes(normalizedFilterName)
      }

      return this.filterOrderList
        .filter(item => {
          if (this.filters.name.length) {
            return (
              matchesFilter(item, item.accountName) ||
              matchesFilter(item, item.customerId) ||
              matchesFilter(item, item.orderId) ||
              matchesFilter(item, item.accountPhoneNumber) ||
              matchesFilter(item, item.posMemberId)
            )
          }
          return true
        }).filter(
          (item) => {
            if (this.filters.paymentMethod?.length) {
              return item.paymentMethod?.toUpperCase().includes(this.filters.paymentMethod)
            }
            return true
          }
        )
    },
    paginatedData () {
      return this.filterOrders.slice(
        (this.currentPage - 1) * this.limit,
        this.currentPage * this.limit
      )
    },
    statusOptions: function () {
      return this.statusTypes.filter(
        (item) => item.value !== 'PLACED' && item.value !== 'PENDING'
      )
    },
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0
  },
  watch: {
    refundItemsMapping (newValue) {
      if (this.orderDetails && newValue) {
        const totalRefundQty = newValue?.map((e) => e.quantity).reduce((a, b) => a + b, 0)
        const totalOriginalQty = this.orderDetails.orderItems?.map((e) => e.quantity).reduce((a, b) => a + b, 0)
        if (totalOriginalQty === totalRefundQty) {
          this.isCancelEntire = true
        } else {
          this.isCancelEntire = false
          this.refundAmount = this.sumRefundAmount.amount
        }
      }
    },
    filterOrders (newValue) {
      if (newValue.length > 0) {
        this.currentPage = 1
      }
    },
    immediate: true
  },
  methods: {
    deepClone (obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj
      }

      const clone = Array.isArray(obj) ? [] : {}

      for (const key in obj) {
        const value = obj[key]
        if (key === 'quantity') {
          clone[key] = null
        } else {
          clone[key] = this.deepClone(value)
        }
      }

      return clone
    },
    async ordersInit () {
      this.isLoading = true
      await orderService.getAllOrder().then((res) => {
        this.$store.commit('setOrderList', res.data)
      })
      this.isLoading = false
    },
    async getOrderDetails (id) {
      this.orderDetails = {}
      this.orderDetails = await orderService.getOrderDetails(id)

      this.driver.trackingNumber = this.orderDetails?.trackingNumber || ''
      this.driver.driverTracking = this.orderDetails?.driverTracking || ''
      this.driver.name = this.orderDetails?.driverName || ''
      this.driver.phone = this.orderDetails?.driverPhone || ''

      this.refundItems = this.deepClone(this.orderDetails.orderItems)
      if (this.orderDetails.refundedAmount) {
        this.refundDetails = await orderService.getRefundDetails(id)
        this.orderDetails = {
          ...this.orderDetails,
          orderItems: this.orderDetails.orderItems.map((el) => ({
            ...el,
            refundDetails: this.refundDetails.find((s) => s.productId === el.productId)
          }))
        }
      }
      if (this.orderDetails.orderFulFilmentMethod === 'PICKUP') {
        const branchDetails = await orderService.getBranchDetails(this.orderDetails.orderPickupViewDTO.storeId)
        this.orderDetails.orderPickupViewDTO = {
          ...this.orderDetails.orderPickupViewDTO,
          branch: branchDetails
        }
      }
    },
    async getOrderStatusDetails (id) {
      this.orderDetails = {}
      const orderStatusTime = await orderService.getOrderStatusTimeUpdate(id)
      this.statusesInfo = orderStatusTime.orderStatusTimeSingleDTOList
        .filter((item) => item.orderStatus !== 'PENDING')
        .map(event => {
          const statusInfo = this.statusMapping[event.orderStatus]
          const date = new Date(event.statusUpdateTime)
          return {
            status: event.orderStatus,
            displayName: statusInfo.displayName,
            description: statusInfo.description,
            date: date.toLocaleDateString(),
            time: date.toLocaleTimeString()
          }
        })
    },
    async showOrderStatusDialog (id, status) {
      this.selectedId = id
      this.selectedStatus = status
      await this.getOrderStatusDetails(id)
      this.$refs.orderStatusDetailsDialog.show()
    },
    hideOrderStatusDialog () {
      this.$refs.orderStatusDetailsDialog.hide()
      this.selectedStatus = null
      this.selectedId = null
    },
    handleCheckboxChange (value) {
      if (value) {
        this.refundAmount = this.orderDetails.finalAmount
        this.refundItems = this.orderDetails.orderItems
      } else {
        this.refundAmount = null
        this.refundItems = this.deepClone(this.orderDetails.orderItems)
      }
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    formatIOSDate (dateString) {
      const date = new Date(dateString)
      const formattedDate = date.toLocaleString('cn-HK', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })
      return formattedDate
    },
    showFpsEvidenceModal (reference) {
      this.uploadedReference = reference
      this.$refs.fpsEvidenceModal.show()
    },
    hideFpsEvidenceModal () {
      this.$refs.fpsEvidenceModal.hide()
      this.uploadedReference = null
    },
    async showDetailModal (id) {
      this.selectedId = id
      this.getOrderDetails(id)
      this.$refs.viewDetailModal.show()
    },
    hideDetailModal () {
      this.$refs.viewDetailModal.hide()
      this.selectedStatus = null
    },
    async showEditModal (id) {
      this.selectedId = id
      this.getOrderDetails(id)
      this.$refs.editOrderModal.show()
    },
    hideEditModal () {
      this.$refs.editOrderModal.hide()
      this.selectedStatus = null
    },
    async showAssignDriverModal (id) {
      this.getOrderDetails(id)
      this.selectedId = id
      this.$refs.onAssignDriver.show()
    },
    hideAssignDriverModal () {
      this.driver.name = ''
      this.driver.phone = ''
      this.driver.driverTracking = ''
      this.driver.trackingNumber = ''
      this.$refs.onAssignDriver.hide()
    },
    async showRefundModal (id) {
      this.selectedId = id
      this.getOrderDetails(id)
      this.$bvModal.show('refund-modal')
    },
    async hideRefundModal () {
      this.isCancelEntire = false
      this.refundAmount = null
      this.selectedRefundMethod = ''
      this.selectedStatus = null
      this.$bvModal.hide('refund-modal')
    },
    async showShippingAddressModal (id) {
      this.selectedId = id
      await this.getOrderDetails(id)
      this.setNewAddress = this.orderDetails.address
      this.$bvModal.show('ShippingAddressModal')
    },
    async hideShippingAddressModal () {
      this.setNewAddress = ''
      this.$bvModal.hide('ShippingAddressModal')
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },
    async submitChangeAddressForm () {
      this.isSubmitting = true
      await orderService.setNewAddressForOrder(this.selectedId, this.setNewAddress).then(
        (res) => {
          if (res.data.success) {
            orderService.getAllOrder().then((response) => {
              this.$store.commit('setOrderList', response.data)
            })
            this.makeToast('success', '訂單管理', '送貨地址已更改')
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.isSubmitting = false
      this.hideShippingAddressModal()
    },
    async onApproveFps (isAccepted) {
      if (isAccepted) {
        this.isSubmitting = true
      } else {
        this.isReject = true
      }
      await orderService.approveOrRejectFps(this.selectedId, isAccepted).then(
        (res) => {
          if (res.success) {
            orderService.getAllOrder().then((res) => {
              this.$store.commit('setOrderList', res.data)
            })
            this.makeToast('success', '訂單管理', 'Status is updated')
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.isSubmitting = false
      this.isReject = false
      this.$refs.viewDetailModal.hide()
      this.selectedStatus = null
    },
    async onApproveOrRejectFps () {
      if (this.selectedStatus === null) return
      this.isSubmitting = true
      await orderService.updateOrderStatus(this.selectedId, this.selectedStatus).then(
        (res) => {
          if (res.success) {
            orderService.getAllOrder().then((res) => {
              this.$store.commit('setOrderList', res.data)
            })
            this.makeToast('success', '訂單管理', `"訂單編號 #${this.selectedId} - 狀態已更新`)
          }
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
      this.isSubmitting = false
      this.$refs.editOrderModal.hide()
      this.$refs.viewDetailModal.hide()
      this.selectedStatus = null
    },
    async onSubmitAssignDriver () {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          return
        }
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('driverName', this.driver.name || '')
        formData.append('driverPhone', this.driver.phone || '')
        formData.append('driverTracking', this.driver.driverTracking || '')
        formData.append('trackingNumber', this.driver.trackingNumber || '')
        await orderService.assignDriverToOrder(this.selectedId, formData).then(
          res => {
            if (res.data.success) {
              this.makeToast('success', '運送資訊', '運送資訊已新增')
              orderService.getAllOrder().then((res) => {
                this.$store.commit('setOrderList', res.data)
              })
              this.hideAssignDriverModal()
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
        this.isSubmitting = false
      })
    },
    async onSubmitRefund () {
      this.isSubmitting = true
      if (this.isCancelEntire) {
        await orderService.refundCancelOrder(this.selectedId, this.selectedRefundMethod).then(
          (res) => {
            if (res.data.success) {
              orderService.getAllOrder().then((resData) => {
                this.$store.commit('setOrderList', resData.data)
              })
              this.makeToast('success', '訂單管理', `訂單 #${this.selectedId} 退款成功。退款金額： HKD${this.refundAmount}。`)
            } else {
              this.makeToast('danger', '訂單管理', res.data.message)
            }
          },
          (err) => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
        this.hideRefundModal()
      } else {
        const payload = { ...this.sumRefundAmount, amount: this.refundAmount }
        await orderService.partialRefundOrder(this.selectedId, payload).then(
          (res) => {
            if (res.data.success) {
              orderService.getAllOrder().then((resData) => {
                this.$store.commit('setOrderList', resData.data)
              })
              this.makeToast('success', '訂單管理', `訂單 #${this.selectedId} 退款成功。退款金額： HKD${this.sumRefundAmount.amount}。`)
            } else {
              this.makeToast('danger', '訂單管理', res.data.message)
            }
          },
          (err) => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
          }
        )
        this.hideRefundModal()
      }
      this.isSubmitting = false
    },
    numberPositiveIntegerOnly (e, maxValue) {
      // List of allowed keys: number keys, arrow keys, backspace, and delete
      const allowedKeys = [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
        'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
        'Backspace', 'Delete'
      ]

      // Prevent default action if the key pressed is not in the allowedKeys list
      if (!allowedKeys.includes(e.key)) {
        e.preventDefault()
        return false
      }

      // Check the result after the key press to ensure it does not exceed the maximum value
      const currentValue = e.target.value
      const futureValue = parseInt(currentValue + e.key)

      // Prevent the input if the future value exceeds the maximum value
      if (!isNaN(futureValue) && futureValue > maxValue) {
        e.preventDefault()
        return false
      }
    },
    onDecimalInputOnly (e) {
      decimalInputOnly(e)
    },
    getPaymentMethod (value) {
      if (!value) return '-'
      const option = this.paymentOptions.find((f) => f.value === value?.toUpperCase())
      if (!option) {
        return value?.toUpperCase()
      }
      return option.text
    }
  }
}
</script>

<template>
  <b-container fluid>
    <div class="card border-0 shadow mb-4 p-4">
      <div
        class="d-flex justify-content-between"
        style="gap: 14px; flex-flow: row wrap"
      >
        <div
          v-for="(status, idx) in statusTypes"
          :key="idx"
          style="height: 100%"
          @click="(filters.name = ''), (filters.paymentMethod = null)"
        >
          <router-link :to="`/dispatcher/${status.value}`">
            <button
              type="button"
              class="rounded-6px py-2 px-4"
              style="
                background-color: white;
                border: 1px solid #e6d9d1;
                color: #9f836e;
                height: 100%;
              "
            >
              {{ status.text }}
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div>
      <b-row class="mb-4">
        <b-col cols="12" xl="3" md="6" class="mb-2">
          <div class="input-group">
            <span class="input-group-text input-group-text--addon-left">
              <b-icon icon="search"></b-icon>
            </span>
            <input
              v-model="filters.name"
              type="text"
              class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
              placeholder="搜索"
            />
          </div>
        </b-col>
        <b-col cols="12" md="3" xl="2" class="mb-2">
          <b-form-select
            style="color: #bec0d0"
            id="paymentMethod"
            class="form-control--h46px rounded-10px"
            name="paymentMethod"
            v-model="filters.paymentMethod"
            :options="paymentOptions"
          ></b-form-select>
        </b-col>
      </b-row>
    </div>
    <TableData :items="paginatedData" :headers="headers" :isLoading="isLoading">
      <template v-slot:custom-orderFulFilmentMethod="{ item }">
        <span v-if="item.orderFulFilmentMethod.toUpperCase() === 'DELIVERY'">送貨</span>
        <span v-else>門店自取</span>
      </template>
      <template v-slot:custom-status="{ item }">
        <StatusButton :status="item.status"></StatusButton>
      </template>
      <template v-slot:custom-createdDate="{ item }">
        {{ item.createdDate | formatDate }}
      </template>
      <template v-slot:custom-paymentMethod="{ item }">
        {{ getPaymentMethod(item.paymentMethod) }}
      </template>
      <template v-slot:custom-reference="{ item }">
        <div
          class="text-brown"
          v-if="item.paymentMethod.toUpperCase() === 'FPS'"
          @click="showFpsEvidenceModal(item.reference)"
          style="text-decoration: underline; cursor: pointer"
        >
          查看
        </div>
      </template>
      <template v-slot:custom-action="{ item }">
        <b-dropdown
          boundary="viewport"
          variant="link"
          right
          toggle-class="text-white text-decoration-none"
          no-caret
        >
          <template #button-content>
            <b-icon
              icon="three-dots"
              font-scale="1.5"
              class="text-dark"
            ></b-icon>
          </template>
          <b-dropdown-item @click="showDetailModal(item.orderId)">
            看詳情</b-dropdown-item
          >
          <b-dropdown-item @click="showOrderStatusDialog(item.orderId ,item.status)">
            訂單狀態詳情</b-dropdown-item
          >
          <b-dropdown-item
            v-if="$route.params.status.toString() !== 'COMPLETE'"
            @click="showShippingAddressModal(item.orderId)"
            >修改配送地址</b-dropdown-item
          >
          <b-dropdown-item
            v-if="$route.params.status.toString() === 'CONFIRMED'"
            @click="showAssignDriverModal(item.orderId)"
          >
            新增運輸資訊</b-dropdown-item
          >
          <b-dropdown-item @click="showEditModal(item.orderId)"
            >更改訂單狀態</b-dropdown-item
          >
          <b-dropdown-item
            v-if="
              !(
                $route.params.status.toString() === 'PLACED' &&
                item.paymentMethod.toUpperCase() === 'FPS'
              )
            "
            @click="showRefundModal(item.orderId)"
            >退款訂單</b-dropdown-item
          >
        </b-dropdown>
      </template>
    </TableData>
    <b-modal
      ref="fpsEvidenceModal"
      title="FPS 參考"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <div>
        <img
          :src="uploadedReference"
          :alt="uploadedReference"
          width="100%"
          style="min-width: 360px"
        />
      </div>
    </b-modal>
    <b-modal
      ref="viewDetailModal"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
      title="訂單詳情"
    >
      <div
        v-if="Object.keys(orderDetails).length"
        class="card table-responsive border-0 mb-4 px-3"
      >
        <div
          class="d-flex my-2 align-items-center"
          style="gap: 16px; justify-content: space-between"
        >
          <h6 class="self-items-center">
            訂單編號#: {{ orderDetails.orderId }}
          </h6>
          <StatusButton :status="orderDetails.status"></StatusButton>
        </div>
        <div>訂單日期: {{ formatIOSDate(orderDetails.createdDate) }}</div>
        <div>
          付款方式:
          <span>{{ getPaymentMethod(orderDetails.paymentMethod) }}</span>
        </div>
        <div v-if="orderDetails.paymentMethod.toUpperCase() !== 'FPS'">
          Payment intent:
          <span style="font-weight: 500;">
            {{ orderDetails.paymentIntent }}</span
          >
        </div>
        <div class="table-responsive mb-0 mt-2">
          <table class="table table-hover">
            <thead class="bg-brown--light">
              <tr>
                <th class="text-nowrap">產品</th>
                <th class="text-nowrap text-center">單價 (HKD)</th>
                <th class="text-nowrap text-center">數量</th>
                <th
                  class="text-nowrap text-center"
                  v-if="orderDetails.refundedAmount"
                >
                  移除數量
                </th>
                <th class="text-nowrap text-center">折扣 (HKD)</th>
                <th class="text-nowrap text-center">優惠券 (HKD)</th>
                <th class="text-nowrap text-center">總計 (HKD)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orderDetails.orderItems" :key="item.id">
                <td>
                  <div
                    class="d-flex align-items-center"
                    style="gap: 16px; max-width: 220px"
                  >
                    <img
                      :src="item.productImages[0]"
                      width="50px"
                      height="50"
                      style="border-radius: 5px"
                    />
                    <div style="overflow-x: hidden; flex: 1 1 0%">
                      <div style="font-weight: 500;">{{ item.productNameZhHk }}</div>
                      <div>產品編號: {{ item.posId }}</div>
                      <div v-if="item.productOptionViewDTOs.length">
                        <p
                          v-for="option in item.productOptionViewDTOs"
                          :key="option.productOptionId"
                          style="margin: 0;"
                        >
                          選項: {{ option.optionValue }}
                        </p>
                      </div>
                      <div>備註: {{ item.remarks }}</div>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center">{{ item.price }}</td>
                <td class="align-middle text-center">{{ item.quantity }}</td>
                <td
                  class="align-middle text-center"
                  v-if="orderDetails.refundedAmount"
                >
                  <span v-if="item.refundDetails"
                    >-{{ item.refundDetails.quantity }}</span
                  >
                  <span v-else>0</span>
                </td>
                <td class="align-middle text-center">
                  <span v-if="item.discount"
                    >-{{ item.discount }}</span
                  >
                  <span v-else>0</span>
                </td>
                <td class="align-middle text-center">
                  <span v-if="item.couponDiscount"
                    >-{{ item.couponDiscount }}</span
                  >
                  <span v-else>0</span>
                </td>
                <td class="align-middle text-center">
                  {{ item.finalOrderItemAmount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="align-middle text-nowrap" colspan="4"></td>
                <td colspan="3" class="align-middle text-center">
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">小計</div>
                    <div style="width: 50%" class="text-right">
                      HKD
                      {{
                        orderDetails.orderPricingDTO.originalAmount.toLocaleString()
                      }}
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">快遞費</div>
                    <div style="width: 50%" class="text-right">
                      HKD {{ orderDetails.deliveryFee.toLocaleString() }}
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">優惠券總折扣</div>
                    <div style="width: 50%" class="text-right">
                      <span v-if="!orderDetails.orderPricingDTO.totalCouponDiscount"> -HKD 0</span>
                      <span v-else>
                        -HKD
                      {{
                        orderDetails.orderPricingDTO.totalCouponDiscount.toLocaleString()
                      }}
                      </span>
                    </div>
                  </div>
                  <div
                  class="d-flex align-items-center mb-2"
                  style="font-weight: 500"
                  >
                  <div style="width: 50%" class="text-left">總折扣</div>
                  <div style="width: 50%" class="text-right">
                    -HKD
                    {{
                      orderDetails.orderPricingDTO.totalDiscount.toLocaleString()
                    }}
                    </div>
                  </div>
                  <hr />
                  <div
                    class="d-flex align-items-center"
                    style="font-weight: 600"
                  >
                    <div style="width: 50%" class="text-left">總計</div>
                    <div style="width: 50%" class="text-right">
                      HKD {{ orderDetails.finalAmount.toLocaleString() }}
                    </div>
                  </div>
                  <template
                    v-if="
                      orderDetails.refundedAmount || orderDetails.refundMethod
                    "
                  >
                    <hr />
                    <h6 class="text-left">退款詳情</h6>
                    <div
                      class="d-flex align-items-center text-danger mb-2"
                      style="font-weight: 500"
                    >
                      <div style="width: 50%" class="text-left">退款金額</div>
                      <div style="width: 50%" class="text-right">
                        HKD {{ orderDetails.refundedAmount.toLocaleString() }}
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center mb-2"
                      style="font-weight: 500"
                    >
                      <div style="width: 50%" class="text-left">退款方式</div>
                      <div style="width: 50%" class="text-right">
                        {{ orderDetails.refundMethod }}
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <section v-if="orderDetails.orderFulFilmentMethod !== 'PICKUP'">
          <hr class="mt-4" />
            <div>
              <h6>收件人資料</h6>
              <b-row>
                <b-col cols="3">顧客姓名:</b-col>
                <b-col cols="9">{{ orderDetails.customerName }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">電話號碼:</b-col>
                <b-col cols="9">{{ orderDetails.phoneNumber }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">收件地址:</b-col>
                <b-col cols="9">{{ orderDetails.address }}</b-col>
              </b-row>
            </div>
          <template
            v-if="
              orderDetails.driverName ||
              orderDetails.driverPhone ||
              orderDetails.driverTracking ||
              orderDetails.trackingNumber
            "
          >
            <hr class="mt-4" />
            <div>
              <h6>運送資訊</h6>
              <b-row v-if="orderDetails.driverName">
                <b-col cols="3">司機姓名:</b-col>
                <b-col cols="9">{{ orderDetails.driverName }}</b-col>
              </b-row>
              <b-row v-if="orderDetails.driverPhone">
                <b-col cols="3">電話號碼:</b-col>
                <b-col cols="9">{{ orderDetails.driverPhone }}</b-col>
              </b-row>
              <b-row v-if="orderDetails.driverTracking">
                <b-col cols="3">Courier Tracking URL:</b-col>
                <b-col cols="9">{{ orderDetails.driverTracking }}</b-col>
              </b-row>
              <b-row v-if="orderDetails.trackingNumber">
                <b-col cols="3">Tracking number:</b-col>
                <b-col cols="9">{{ orderDetails.trackingNumber }}</b-col>
              </b-row>
            </div>
          </template>
        </section>
        <section v-else>
          <template
            v-if="
              !!orderDetails.orderPickupViewDTO &&
              Object.keys(orderDetails.orderPickupViewDTO).length
            "
          >
            <hr class="mt-4" />
            <div>
              <h6>收件人資料</h6>
              <b-row v-if="orderDetails.orderPickupViewDTO.customerName">
                <b-col cols="3">顧客姓名:</b-col>
                <b-col cols="9"
                  >{{ orderDetails.orderPickupViewDTO.title }}
                  {{ orderDetails.orderPickupViewDTO.customerName }}</b-col
                >
              </b-row>
              <b-row v-if="orderDetails.orderPickupViewDTO.email">
                <b-col cols="3">電子郵件:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.email
                }}</b-col>
              </b-row>
              <b-row v-if="orderDetails.orderPickupViewDTO.phone">
                <b-col cols="3">手機號碼:</b-col>
                <b-col cols="9">
                  {{ orderDetails.orderPickupViewDTO.countryCode
                  }}{{ orderDetails.orderPickupViewDTO.phone }}
                </b-col>
              </b-row>
            </div>
            <hr class="mt-4" />
            <div v-if="orderDetails.orderPickupViewDTO.branch">
              <h6>取貨資訊</h6>
              <b-row>
                <b-col cols="3">電話號碼:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.branch.phone
                }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">店家名稱:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.branch.nameZhHk
                }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="3">地址:</b-col>
                <b-col cols="9">{{
                  orderDetails.orderPickupViewDTO.branch.addressZhHk
                }}</b-col>
              </b-row>
            </div>
          </template>
        </section>
        <hr class="mt-4" />
        <div
          v-if="
            $route.params.status.toString() === 'PLACED' &&
            orderDetails.paymentMethod.toUpperCase() === 'FPS'
          "
          class="d-flex mt-5 align-items-center justify-content-end"
        >
          <b-button
            @click="onApproveFps(false)"
            class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-4 me-lg-4"
            ><b-spinner v-if="isReject" small class="mr-2"></b-spinner
            >拒絕</b-button
          >
          <b-button
            @click="onApproveFps(true)"
            type="button"
            variant="primary"
            class="rounded-10px px-3 px-lg-5"
            :disabled="isSubmitting"
            ><b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
            >批准</b-button
          >
        </div>
        <b-form v-else>
          <h6 class="text-brown">更改訂單狀態</h6>
          <b-form-select
            v-model="selectedStatus"
            :options="
              statusOptions.filter((s) => s.value !== orderDetails.status)
            "
          ></b-form-select>
          <div class="d-flex mt-4 align-items-center justify-content-end">
            <b-button
              @click="hideDetailModal()"
              class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
              >取消</b-button
            >
            <b-button
              @click="onApproveOrRejectFps()"
              type="button"
              variant="primary"
              class="rounded-10px px-3 px-lg-5"
              :disabled="isSubmitting"
              ><b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >保存</b-button
            >
          </div>
        </b-form>
      </div>
    </b-modal>

    <b-modal
      ref="onAssignDriver"
      centered
      size="lg"
      no-close-on-backdrop
      hide-header
      hide-footer
    >
      <b-form class="p-3">
        <b-form @submit.stop.prevent="onSubmitAssignDriver">
          <h5 class="text-brown mb-4">新增運輸資訊</h5>
          <div
            class="d-flex mb-4 text-brown align-items-center"
            style="gap: 16px; justify-content: space-between"
          >
            <h6 class="self-items-center">
              訂單編號#: {{ orderDetails.orderId }}
            </h6>
            <StatusButton :status="orderDetails.status"></StatusButton>
          </div>
          <div class="mb-5 mt-2">
            <b-row class="mb-3">
              <b-col sm="4">
                <label for="driverName" class="text-brown">司機姓名</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  id="driverName"
                  class="rounded-0"
                  name="driverName"
                  v-model="driver.name"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col sm="4">
                <label for="driverPhone" class="text-brown">電話號碼</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  id="driverPhone"
                  class="rounded-0"
                  name="driverPhone"
                  v-model="driver.phone"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col sm="4">
                <label for="driverTracking" class="text-brown"
                  >Courier Tracking URL</label
                >
              </b-col>
              <b-col sm="8">
                <b-form-input
                  id="driverTracking"
                  class="rounded-0"
                  name="driverTracking"
                  v-model="driver.driverTracking"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col sm="4">
                <label for="driverTracking" class="text-brown"
                  >Tracking number</label
                >
              </b-col>
              <b-col sm="8">
                <b-form-input
                  id="trackingNumber"
                  class="rounded-0"
                  name="trackingNumber"
                  v-model="driver.trackingNumber"
                ></b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              @click="hideAssignDriverModal()"
              class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
              >取消</b-button
            >
            <b-button
              :disabled="isSubmitting"
              type="submit"
              variant="primary"
              class="rounded-10px px-3 px-lg-5"
            >
              <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >保存</b-button
            >
          </div>
        </b-form>
      </b-form>
    </b-modal>
    <b-modal
      ref="editOrderModal"
      centered
      size="md"
      no-close-on-backdrop
      hide-header
      hide-footer
    >
      <b-form class="p-3">
        <h5 class="text-brown mb-4">更改訂單狀態</h5>
        <div
          class="d-flex mb-4 text-brown align-items-center"
          style="gap: 16px; justify-content: space-between"
        >
          <h6 class="self-items-center">
            訂單編號#: {{ orderDetails.orderId }}
          </h6>
          <StatusButton :status="orderDetails.status"></StatusButton>
        </div>
        <b-form-select
          v-model="selectedStatus"
          :options="statusOptions"
        ></b-form-select>
        <div class="d-flex mt-4 align-items-center justify-content-end">
          <b-button
            @click="hideEditModal()"
            class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
            >取消</b-button
          >
          <b-button
            @click="onApproveOrRejectFps()"
            type="button"
            variant="primary"
            class="rounded-10px px-3 px-lg-5"
            :disabled="isSubmitting"
          >
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
            >儲存</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="refund-modal"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div
        v-if="Object.keys(orderDetails).length"
        class="card table-responsive border-0 mb-4 px-3"
      >
        <h5 class="text-brown mb-4">退款訂單</h5>
        <div
          class="d-flex my-2 align-items-center"
          style="gap: 16px; justify-content: space-between"
        >
          <h6 class="self-items-center">
            訂單編號#: {{ orderDetails.orderId }}
          </h6>
          <StatusButton :status="orderDetails.status"></StatusButton>
        </div>
        <div>訂單日期: {{ formatIOSDate(orderDetails.createdDate) }}</div>
        <div>
          付款方式:
          <span>{{ getPaymentMethod(orderDetails.paymentMethod) }}</span>
        </div>
        <div class="table-responsive mb-0 mt-2">
          <table class="table table-hover">
            <thead class="bg-brown--light">
              <tr>
                <th class="text-nowrap">產品</th>
                <th class="text-nowrap text-center">單價 (HKD)</th>
                <th class="text-nowrap text-center">移除數量</th>
                <th class="text-nowrap text-center">折扣 (HKD)</th>
                <th class="text-nowrap text-center">優惠券 (HKD)</th>
                <th class="text-nowrap text-center">總計 (HKD)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, idx) in orderDetails.orderItems" :key="item.id">
                <td>
                  <div
                    class="d-flex align-items-center"
                    style="gap: 16px; max-width: 220px"
                  >
                    <img
                      :src="item.productImages[0]"
                      width="50px"
                      height="50"
                      style="border-radius: 5px"
                    />
                    <div style="overflow-x: hidden; flex: 1 1 0%">
                      <div>{{ item.productNameZhHk }}</div>
                      <div style="font-size: 12px">
                        產品編號: {{ item.posId }}
                      </div>
                      <div
                        style="font-size: 12px"
                        v-if="item.productOptionViewDTOs.length"
                      >
                        <p
                          v-for="option in item.productOptionViewDTOs"
                          :key="option.productOptionId"
                        >
                          選項: {{ option.optionValue }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center">{{ item.price }}</td>
                <td class="align-middle text-left">
                  <b-input-group style="width: 120px">
                    <template #append>
                      <b-input-group-text class="rounded-0"
                        >/ {{ item.quantity }}
                      </b-input-group-text>
                    </template>
                    <b-form-input
                      :disabled="isCancelEntire"
                      :id="'refundAmount-' + idx"
                      autocomplete="off"
                      class="rounded-0"
                      :name="'refundAmount-' + idx"
                      v-model="refundItems[idx].quantity"
                      @keydown="
                        numberPositiveIntegerOnly($event, item.quantity)
                      "
                    ></b-form-input>
                  </b-input-group>
                </td>
                <td class="align-middle text-center">
                  <span v-if="item.discount"
                    >-{{ item.discount }}</span
                  >
                  <span v-else>0</span>
                </td>
                <td class="align-middle text-center">
                  <span v-if="item.couponDiscount"
                    >-{{ item.couponDiscount }}</span
                  >
                  <span v-else>0</span>
                </td>
                <td class="align-middle text-center">
                  {{ item.finalOrderItemAmount }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="align-middle text-nowrap" colspan="3"></td>
                <td colspan="3" class="align-middle text-center">
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">小計</div>
                    <div style="width: 50%" class="text-right">
                      HKD
                      {{
                        orderDetails.orderPricingDTO.originalAmount.toLocaleString()
                      }}
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">快遞費</div>
                    <div style="width: 50%" class="text-right">
                      HKD {{ orderDetails.deliveryFee.toLocaleString() }}
                    </div>
                  </div>
                  <div
                    class="d-flex align-items-center mb-2"
                    style="font-weight: 500"
                  >
                    <div style="width: 50%" class="text-left">優惠券總折扣</div>
                    <div style="width: 50%" class="text-right">
                      <span v-if="!orderDetails.orderPricingDTO.totalCouponDiscount"> -HKD 0</span>
                      <span v-else>
                        -HKD
                      {{
                        orderDetails.orderPricingDTO.totalCouponDiscount.toLocaleString()
                      }}
                      </span>
                    </div>
                  </div>
                  <div
                  class="d-flex align-items-center mb-2"
                  style="font-weight: 500"
                  >
                  <div style="width: 50%" class="text-left">總折扣</div>
                  <div style="width: 50%" class="text-right">
                    -HKD
                    {{
                      orderDetails.orderPricingDTO.totalDiscount.toLocaleString()
                    }}
                    </div>
                  </div>

                  <hr />
                  <div
                    class="d-flex align-items-center"
                    style="font-weight: 600"
                  >
                    <div style="width: 50%" class="text-left">總計</div>
                    <div style="width: 50%" class="text-right">
                      HKD {{ orderDetails.finalAmount.toLocaleString() }}
                    </div>
                  </div>
                  <template
                    v-if="
                      orderDetails.refundedAmount || orderDetails.refundMethod
                    "
                  >
                    <hr />
                    <h6 class="text-left">退款詳情</h6>
                    <div
                      class="d-flex align-items-center text-danger mb-2"
                      style="font-weight: 500"
                    >
                      <div style="width: 50%" class="text-left">退款金額</div>
                      <div style="width: 50%" class="text-right">
                        HKD {{ orderDetails.refundedAmount.toLocaleString() }}
                      </div>
                    </div>
                    <div
                      class="d-flex align-items-center mb-2"
                      style="font-weight: 500"
                    >
                      <div style="width: 50%" class="text-left">退款方式</div>
                      <div style="width: 50%" class="text-right">
                        {{ orderDetails.refundMethod }}
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <hr />
        <b-form @submit.stop.prevent="onSubmitRefund">
          <h5 class="text-brown mb-4">新增退款訂單</h5>
          <b-row class="mb-3">
            <b-col sm="4">
              <label for="posId" class="text-brown">取消整個訂單</label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-checkbox
                name="check-button"
                class="custom-switch--success"
                switch
                @change="handleCheckboxChange"
                v-model="isCancelEntire"
                :checked="isCancelEntire"
              >
              </b-form-checkbox>
            </b-col>
          </b-row>
          <div class="mb-4">
            <b-row class="mb-3" v-if="!isCancelEntire">
              <b-col sm="4">
                <label class="text-brown">最高退款金額</label>
              </b-col>
              <b-col sm="8"><p>HKD {{ maxRefundAmount}}</p></b-col>
              </b-row>
            <b-row class="mb-3">
              <b-col sm="4">
                <label for="driverPhone" class="text-brown">退款金額</label>
              </b-col>
              <b-col sm="8">
                <b-form-input
                  :disabled="isCancelEntire"
                  id="refundAmount"
                  class="rounded-0"
                  name="refundAmount"
                  v-model="refundAmount"
                  @keydown="onDecimalInputOnly"
                  autocomplete="off"
                  data-vv-as="refundAmount"
                  aria-describedby="input-baseDeliveryFee-live-feedback"
                  v-validate="`required|max_value:${maxRefundAmount}`"
                  :state="validateState('refundAmount')"
                ></b-form-input>
                <b-form-invalid-feedback id="input-refundAmount-live-feedback">{{
              veeErrors.first("refundAmount")
            }}</b-form-invalid-feedback>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col sm="4">
                <label for="driverTracking" class="text-brown">退款方式</label>
              </b-col>
              <b-col sm="8">
                <b-form-select
                  v-model="selectedRefundMethod"
                  :options="
                    orderDetails.paymentMethod.toUpperCase() === 'FPS'
                      ? ['MANUAL']
                      : ['STRIPE', 'MANUAL']
                  "
                ></b-form-select>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex align-items-center justify-content-end">
            <b-button
              @click="hideRefundModal()"
              class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
              >取消</b-button
            >
            <b-button
              :disabled="
                !(selectedRefundMethod && sumRefundAmount.amount) ||
                isSubmitting || !hasVeeErrors
              "
              type="submit"
              variant="primary"
              class="rounded-10px px-3 px-lg-5"
            >
              <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >提交</b-button
            >
          </div>
        </b-form>
      </div>
    </b-modal>
    <b-modal
      id="ShippingAddressModal"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <b-form class="p-3" @submit.prevent="submitChangeAddressForm">
        <h5 class="text-brown mb-4">修改配送地址</h5>
        <div
          class="d-flex mb-4 text-brown align-items-center"
          style="gap: 16px; justify-content: space-between"
        >
          <h6 class="self-items-center">
            訂單編號#: {{ orderDetails.orderId }}
          </h6>
          <StatusButton :status="orderDetails.status"></StatusButton>
        </div>
        <b-form-textarea
          rows="3"
          max-rows="6"
          id="setNewAddress"
          class="rounded-0"
          name="setNewAddress"
          v-model="setNewAddress"
          v-validate="{ required: true, max: 2000 }"
        ></b-form-textarea>
        <div class="d-flex mt-4 align-items-center justify-content-end">
          <b-button
            @click="hideShippingAddressModal()"
            class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
            >關閉</b-button
          >
          <b-button
            :disabled="!setNewAddress.trim() || isSubmitting"
            type="submit"
            variant="primary"
            class="rounded-10px px-3 px-lg-5"
          >
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
            >保存更改</b-button
          >
        </div>
      </b-form>
    </b-modal>
    <OrderStatusDetails
    ref="orderStatusDetailsDialog"
    :statusDetails="statusesInfo"
    :status="selectedStatus"
    :orderId="selectedId"
    @hide="hideOrderStatusDialog()"/>
    <Pagination
      v-if="filterOrders.length && !isLoading"
      v-model="currentPage"
      :list-data="filterOrders"
      :limit="limit"
    />
  </b-container>
</template>
<style scoped>
.router-link-active > button,
.router-link-exact-active > button,
.router-link-active.router-link-exact-active > button {
  background: #9f836e !important;
  border: #9f836e !important;
  color: white !important;
}
</style>
