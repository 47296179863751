<template>
  <b-modal
    centered
    size="md"
    no-close-on-backdrop
    hide-footer
    title="訂單狀態詳情"
    ref="orderStatusDetailsDialog"
    @hide="onHide"
  >
    <section class="px-4 py-3">
      <div
        class="d-flex mb-2 text-brown align-items-center"
        style="gap: 16px; justify-content: space-between"
      >
        <h6 class="self-items-center">訂單編號#: {{ orderId }}</h6>
        <StatusButton :status="status"></StatusButton>
      </div>
      <div class="timeline ml-2">
        <div
          v-for="(event, index) in statusDetails"
          :key="event.status"
          class="timeline-item"
        >
          <div class="timeline-dot"></div>
          <div class="timeline-content">
            <div class="status-name" style="margin-top: -5px;">{{ event.displayName }} </div>
            <div class="timeline-description mb-1">{{ event.date }}</div>
            <div class="timeline-location"> {{ event.time }} - {{ event.description }}</div>
          </div>
          <div
            v-if="index < statusDetails.length - 1"
            class="timeline-connector"
          ></div>
        </div>
      </div>
    </section>
  </b-modal>
</template>

<script>
import StatusButton from '../../../components/StatusButton.vue'

export default {
  name: 'OrderDialog',
  props: {
    statusDetails: {
      type: Array,
      required: true
    },
    orderId: {
      type: Number,
      default: 0
    },
    status: {
      type: String,
      default: ''
    }
  },
  components: {
    StatusButton
  },
  methods: {
    show () {
      this.$refs.orderStatusDetailsDialog.show()
    },
    hide () {
      this.$refs.orderStatusDetailsDialog.hide()
    },
    onHide () {
      this.$emit('hide')
    }
  }
}
</script>

<style>
.timeline {
  position: relative;
  padding: 1rem 0;
}

.timeline-item {
  display: flex;
  position: relative;
  padding-bottom: 1.5rem;
  padding-left: 20px;
}

.timeline-dot {
  width: 10px;
  height: 10px;
  background-color: #9F836E;
  border-radius: 50%;
  position: absolute;
  left: -5px;
  top: 0;
  z-index: 1;
}

.timeline-content {
  margin-left: 20px;
}

.timeline-connector {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 2px;
  height: calc(100% + 10px);
  background-color: #e6d9d1;
  z-index: 0
}

.status-name,
.timeline-time {
  font-weight: 600;
  color: #4a4350;
}

.timeline-description {
  margin-top: 5px;
}

.timeline-location {
  color: #413947;
}
</style>
