export const statusMapping = {
  PLACED: {
    displayName: '已下單',
    description: '訂單已收到'
  },
  PAYMENT_INCOMPLETE: {
    displayName: '未付款',
    description: '訂單尚未付款'
  },
  CONFIRMED: {
    displayName: '已確認',
    description: '訂單已確認'
  },
  READY_FOR_PICKUP: {
    displayName: '門市待取',
    description: '訂單已送達門市，待客戶取貨'
  },
  OUT_FOR_DELIVERY: {
    displayName: '送遞中',
    description: '訂單正在配送中'
  },
  COMPLETE: {
    displayName: '已完成',
    description: '訂單交易已完成'
  },
  CANCELLED: {
    displayName: '已取消',
    description: '訂單已取消'
  },
  REFUNDED: {
    displayName: '已退款',
    description: '款項已退還給客戶'
  }
}
export const statusTypes = [
  {
    text: '已下單',
    value: 'PLACED'
  },
  {
    text: '已確認',
    value: 'CONFIRMED'
  },
  {
    text: '未付款',
    value: 'PAYMENT_INCOMPLETE'
  },
  {
    text: '送遞中',
    value: 'OUT_FOR_DELIVERY'
  },
  {
    text: '門市待取',
    value: 'READY_FOR_PICKUP'
  },
  {
    text: '已完成',
    value: 'COMPLETE'
  },
  {
    text: '已取消',
    value: 'CANCELLED'
  },
  {
    text: '已退款',
    value: 'REFUNDED'
  }
]
